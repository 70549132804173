<template>
  <div>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :can-change-status="false"
    >
      <template
        v-slot:custom_actions="{ item, generalActions }"
      >
        <b-link
          v-if="item.actions.can_view"
          v-b-tooltip.hover.v-primary
          :title="$t('Details')"
          class="ml-1"
          :to="{ name: 'page-details', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-link>
      </template>
    </TableIndex>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resourceName: 'pages-builder',
      singularName: 'page-builder',
      searchFields: {
        title: null,
        slug: null,
        status: null,
      },
      statuses: [
        {
          id: 'active',
          name: 'Active',
        },
        {
          id: 'inactive',
          name: 'In-Active',
        },
      ],
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
